import React from "react";
import { useParams, useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import "./season-day-list-row-content.css";

const SeasonDayListRowContent = (props) => {
  // Get the value of 'season' from the URL params
  const { sid } = useParams();
  const history = useHistory();

  const handleCreateChallenge = () => {
    history.push({
      pathname: "/challenge",
      state: {
        season: Number(sid),
        day: props.dayCount,
        cid: null,
      },
    });
  };
  const handleShowDayList = () => {
    history.push({
      pathname: `/season-day-question-list/season=${sid}/${props.dayCount}`,
      state: { season: Number(sid), day: props.dayCount },
    });
  };
  return (
    <tr>
      <td>
        <div class="challenges-date"><span class="day-text">day</span><span class="day-number">{props.dayCount}</span></div>
      </td>
      <td>{props.totalQuestions}</td>
      <td>&nbsp;</td>
      <td>{props.simpleCount}</td>
      <td>{props.moderateCount}</td>
      <td>{props.toughCount}</td>
      <td>
        <div className="table-actions">
          <button
            onClick={handleCreateChallenge}
            type="button"
          >
            Create New
          </button>
          <div
            onClick={handleShowDayList}
            title="view"
            className="btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <path d="M1 1L6 6L1 11" stroke="#444444" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </td>
    </tr>
  );
};

SeasonDayListRowContent.defaultProps = {
  toughCount: "0",
  moderateCount: "0",
  simpleCount: "0",
  dayCount: "1",
  totalQuestions: "0",
  rootClassName: "",
};

SeasonDayListRowContent.propTypes = {
  toughCount: PropTypes.number,
  moderateCount: PropTypes.number,
  simpleCount: PropTypes.number,
  dayCount: PropTypes.number,
  totalQuestions: PropTypes.number,
  rootClassName: PropTypes.string,
};

export default SeasonDayListRowContent;
