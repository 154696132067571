import React from 'react'

import PropTypes from 'prop-types'

import './season-day-list-row-title.css'

const SeasonDayListRowTitle = (props) => {
  return (
    <div
      className={`season-day-list-row-title-container ${props.rootClassName} `}
    >
      <div className="season-day-list-row-title-row">
        <div className="season-day-list-row-title-cal">
          <div className="season-day-list-row-title-group1596">
            <span className="season-day-list-row-title-text">Day</span>
          </div>
        </div>
        <div className="season-day-list-row-title-container1">
          <span className="season-day-list-row-title-text1">Questions</span>
        </div>
        <div className="season-day-list-row-title-container2">
          <div className="season-day-list-row-title-group1664">
            <span className="season-day-list-row-title-text2">
              Difficulty Level:
            </span>
            <span className="season-day-list-row-title-text3">Simple</span>
            <span className="season-day-list-row-title-text4">Moderate</span>
            <span className="season-day-list-row-title-text5">Tough</span>
          </div>
        </div>
        <div className="season-day-list-row-title-container3">
          <span className="season-day-list-row-title-text6">Actions</span>
        </div>
      </div>
    </div>
  )
}

SeasonDayListRowTitle.defaultProps = {
  ellipse9Src11:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/866407c3-0062-48a6-bbe8-041716f1848d/d3a7534c-6adb-4fb0-8c72-3a4ab5f987e2?org_if_sml=11396&force_format=original',
  stroke222Alt1: 'Stroke2222705',
  ellipse9Alt11: 'Ellipse92733',
  stroke222Src1:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/866407c3-0062-48a6-bbe8-041716f1848d/c91f281e-2918-4b1a-ac59-98022ffc6662?org_if_sml=1210&force_format=original',
  stroke223Alt1: 'Stroke2232705',
  rootClassName: '',
  text: '1',
  stroke223Src1:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/866407c3-0062-48a6-bbe8-041716f1848d/f255b14a-d3cc-492d-b92b-e4fa1f1122c6?org_if_sml=1339&force_format=original',
}

SeasonDayListRowTitle.propTypes = {
  ellipse9Src11: PropTypes.string,
  stroke222Alt1: PropTypes.string,
  ellipse9Alt11: PropTypes.string,
  stroke222Src1: PropTypes.string,
  stroke223Alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  stroke223Src1: PropTypes.string,
}

export default SeasonDayListRowTitle
