// axiosInstance.js
import axios from "axios";
import store from "../store";

// Set default CSRF token header names
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

// Create an Axios instance with a custom config
const apiClient = axios.create({
  baseURL: "https://backend.90d.design/api/",
  timeout: 10000, // Adjust this timeout according to your needs
  headers: {
    "Content-Type": "multipart/form-data",
    // You can add other common headers here if needed
  },
  withCredentials: true,
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Including the CSRF token in request header
    if (config.method === "post" && store.getState().auth) {
      const csrftoken = store.getState().auth?.user?.csrf_token;
      config.headers["X-CSRFToken"] = csrftoken;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // You can modify the response data here, e.g., parse or transform it
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default apiClient;
