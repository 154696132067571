import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./syllabus-grid.scss"; // Import your CSS file
import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import {
  createOrUpdateSyllabusDetails,
  getSyllabusBySeason,
} from "../api/routes/syllabus";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";
import {
  getColorByDayDifficulty,
  getColorByDifficulty,
} from "../utils/getColorByDayDifficulty";

const GridComponent = () => {
  const location = useLocation();
  const { state } = location;
  const { sid, sname } = state || {}; // syllabus id
  const [syllabusDetails, setSyllabusDetails] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDay, setSelectedDay] = useState(0);

  const handleClick = (day) => {
    setSelectedDay(day);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedDay, selectedCategory]);

  const handleKeyDown = async (event) => {
    if (
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "ArrowUp" ||
      event.key === "ArrowDown"
    ) {
      setSelectedCategory((prev) => "");
      setSelectedDay((prevSelectedDay) => {
        let newSelectedDay = prevSelectedDay;

        if (event.key === "ArrowLeft") {
          newSelectedDay = (newSelectedDay - 1 + 90) % 90 || 90;
        } else if (event.key === "ArrowRight") {
          newSelectedDay = (newSelectedDay + 1) % 90 || 90;
        } else if (event.key === "ArrowUp") {
          newSelectedDay = (newSelectedDay - 10 + 90) % 90 || 90;
        } else if (event.key === "ArrowDown") {
          newSelectedDay = (newSelectedDay + 10) % 90 || 90;
        }

        return newSelectedDay;
      });
    }
    if (
      event.key.toLowerCase() === "s" ||
      event.key.toLowerCase() === "m" ||
      event.key.toLowerCase() === "t"
    ) {
      setSelectedCategory((prevSelectedCategory) => {
        let newSelectedCategory = prevSelectedCategory;
        if (event.key.toLowerCase() === "s") {
          newSelectedCategory = "simple";
        } else if (event.key.toLowerCase() === "m") {
          newSelectedCategory = "medium";
        } else if (event.key.toLowerCase() === "t") {
          newSelectedCategory = "tough";
        }
        return newSelectedCategory;
      });
    }
    if (event.key === "Enter") {
      postOrUpdateSyllabusDetails();
    }
  };

  useEffect(() => {
    fetchListSyllabusDetails();
  }, [forceUpdate]);

  const fetchListSyllabusDetails = async () => {
    try {
      const response = await getSyllabusBySeason(sid);
      if (response && response.data) {
        setSyllabusDetails(response.data.syllabus.syllabusdetails);
      }
    } catch (error) { }
  };

  const postOrUpdateSyllabusDetails = async () => {
    if (!selectedCategory || selectedDay < 1) return;
    const payload = { day: selectedDay, difficulty_level: selectedCategory };
    try {
      const response = await createOrUpdateSyllabusDetails(sid, payload);
      if (response && response.data) {
        navigateToScreenOrToast(response.data);
        setForceUpdate((prev) => !prev);
      }
    } catch (error) { }
  };

  const rows = 9;
  const columns = 10;
  const row_title = [
    "Start  🏁",
    "10+ days",
    "20+ days",
    "30+ days",
    "40+ days",
    "50+ days",
    "60+ days",
    "70+ days",
    "80+ days",
  ];

  const renderGrid = () => {
    const grid = [];
    for (let i = 1; i <= rows; i++) {
      const row = [];
      for (let j = 1; j <= columns; j++) {
        row.push(
          <div
            key={10 * (i - 1) + j}
            onClick={() => handleClick(10 * (i - 1) + j)}
            className="grid-square"
            style={{
              backgroundColor:
                10 * (i - 1) + j === selectedDay && !!selectedCategory
                  ? getColorByDifficulty(selectedCategory)
                  : getColorByDayDifficulty(syllabusDetails, 10 * (i - 1) + j),
              borderWidth: 2,
              borderColor: 10 * (i - 1) + j === selectedDay ? "green" : "",
            }}
          />
        );
      }
      grid.push(
        <div key={i} className="grid-row">
          <div className="row-heading">
            {row_title[i - 1]}
          </div>
          {row}
        </div>
      );
    }

    return grid;
  };

  return (
    <div>
      <Navbar />
      <div className="content-wrap">
        <div className="container">
          <div className="grid-title">L1 Person</div>
          <div className="grid-wrap">
            <div className="grid-left">
              <div className="grid-cols-wrap">
                <div className="grid-col">
                  <div style={{ width: "75px" }}>Days:</div>
                  {[...Array(columns)].map((_, index) => (
                    <div
                      key={index}
                      style={{ width: 64 }}
                      className="col-heading"
                    >{`Day ${index + 1}`}</div>
                  ))}
                </div>
                {renderGrid()}
                <div className="grid-bottom">
                  <span>Win 🏅</span>
                </div>
              </div>
            </div>
            <div className="grid-right">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1575995872537-3793d29d972c?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGlsbHVzdHJhdGlvbnN8ZW58MHx8fHwxNzAwNjM3NDYwfDA&amp;ixlib=rb-4.0.3&amp;h=700"
              />
            </div>
          </div>
        </div>
      </div>
      <FooterMinimal />
    </div>
  );
};

export default GridComponent;
