import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./seasons.scss";
import {
  createSeason,
  editSeason,
  editSeasonStatus,
  getSeason,
  getSeasons,
} from "../api/routes/seasons";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";

const Seasons = (props) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [seasonCreate, setSeasonCreate] = useState(false);
  const [seasonString, setSeasonString] = useState({ name: "" });

  const createNewSeason = () => {
    setSeasonCreate(true)
  }
  const cancelNewSeason = () => {
    setSeasonCreate(false)
  }
  useEffect(() => {
    fetchSeasons();
  }, [forceUpdate]);

  const fetchSeasons = async () => {
    try {
      const response = await getSeasons();
      if (response && response.data) {
        setSeasons(response.data.seasons);
      }
    } catch (error) { }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSeasonString((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const postSeason = async () => {
    if (!seasonString.name) return;
    try {
      const response = await createSeason(seasonString);
      if (response && response.data) {
        setSeasonString({ name: "" });
        navigateToScreenOrToast(response.data);
        setForceUpdate((prev) => !prev);
        setSeasonCreate(false)
      }
    } catch (error) { }
  };

  const navigateToSeasonDayListScreen = (sid) => {
    window.location.href = `/season-day-list/season=${sid}`;
  };

  return (
    <div>
      <Helmet>
        <title>Seasons - 90 D Admin Dashboard</title>
        <meta property="og:title" content="Seasons - 90 D Admin Dashboard" />
      </Helmet>
      <Navbar />
      <div className="content-wrap">
        <div className="container">
          <div className="title-section">
            <h1>Seasons</h1>
            <p>Create and view seasons here.</p>
          </div>
          <div className="seasons-list">
            {seasons &&
              seasons.map((season, index) => (
                <div
                  onClick={() => navigateToSeasonDayListScreen(season.id)}
                  key={index}
                  className="season-item"
                >
                  <div className="season-name">
                    <span>{season?.name}</span>
                  </div>
                </div>
              ))}
            <div className="season-item season-create">
              <div className="season-create-btn" onClick={() => createNewSeason()} title="create new">
                +
              </div>
              {seasonCreate && (
                <div className="season-create-ip">
                  <input
                    type="text"
                    name="name"
                    value={seasonString.name}
                    placeholder="Enter season name"
                    onChange={handleChange}
                  />
                  <button onClick={postSeason}>
                    Create
                  </button>
                  <div onClick={() => cancelNewSeason()} className="cancel-create">cancel</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterMinimal />
    </div>
  );
};

export default Seasons;
