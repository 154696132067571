import React from "react";
import { useParams, useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import "./season-day-question-list-row-content.css";

const SeasonDayQuestionListRowContent = (props) => {
  // Get the value of 'season' from the URL params
  const { sid } = useParams();
  const history = useHistory();

  const handleCreateChallenge = () => {
    const day = props.dayCount;
    history.push({
      pathname: "/challenge",
      state: {
        season: Number(sid),
        day: props.dayCount,
        cid: props.cid,
      },
    });
  };
  return (
    <tr>
      <td>
        <div class="challenges-date"><span class="day-number">{props.questionNumber}</span></div>
      </td>
      <td style={{ textAlign: 'left' }}> {props.questionTitle} {props.cid}</td>
      <td> {props.difficultyLevel}</td>
      <td>
        <div className="table-actions">
          <div
            onClick={handleCreateChallenge}
            title="view"
            className="btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <path d="M1 1L6 6L1 11" stroke="#444444" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </td>
    </tr>
  );
};

SeasonDayQuestionListRowContent.defaultProps = {
  rootClassName: "",
  difficultyLevel: "Simple",
  questionTitle: "Question Title Here",
  dayCount: 1,
  questionNumber: 1,
  cid: 0,
};

SeasonDayQuestionListRowContent.propTypes = {
  rootClassName: PropTypes.string,
  difficultyLevel: PropTypes.string,
  questionTitle: PropTypes.string,
  dayCount: PropTypes.number,
  questionNumber: PropTypes.number,
  cid: PropTypes.number,
};

export default SeasonDayQuestionListRowContent;
