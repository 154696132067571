import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./challenge.scss";
import {
  createChallenge,
  getEditChallenge,
  postEditChallenge,
} from "../api/routes/challenge";
import { UploadButton } from "@bytescale/upload-widget-react";
import { bytescaleOptions } from "../config";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";
import Button from "../components/Button";

const Challenge = (props) => {
  const location = useLocation();
  const { state } = location;
  const { season, day, cid } = state || {};
  const initial = {
    name: "",
    short_description: "",
    description: "",
    requirement: "",
    difficulty_level: "simple",
    screen_short_note: "",
    screen: "",
    screen_logo: "",
    design_screen: "",
    reference_link: "https://appshots.design/",
  };

  const [challenge, setChallenge] = useState(initial);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (!cid) return;
    getCurrentChallenege();
  }, [cid, forceUpdate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setChallenge((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getCurrentChallenege = async () => {
    try {
      const response = await getEditChallenge(cid);
      if (response && response.data) {
        setChallenge(response.data.challenge);
      }
    } catch (error) { }
  };

  const editCurrentChallenege = async () => {
    try {
      const response = await postEditChallenge(cid, challenge);
      if (response && response.data) {
        navigateToScreenOrToast(response.data);
        setForceUpdate((prev) => !prev);
      }
    } catch (error) { }
  };

  const postCurrentChallenge = async () => {
    try {
      const response = await createChallenge(season, day, challenge);
      if (response && response.data) {
        setChallenge(initial);
        const season =
          (response.data.redirect && response.data.redirect.season_id) || 1;
        window.location.href = `/season-day-list/season=${season}`;
      }
    } catch (error) { }
  };

  const postCreateEditChallenge = () => {
    if (cid) {
      editCurrentChallenege();
      return;
    }
    postCurrentChallenge();
  };

  return (
    <div>
      <Helmet>
        <title>Challenge - 90 D Admin Dashboard</title>
        <meta property="og:title" content="Challenge - 90 D Admin Dashboard" />
      </Helmet>
      <Navbar />
      <div className="content-wrap">
        <div className="container">
          <div className="challenges-wrap">
            <div className="challenges-left">
              <div className={`challenges-date`}>
                <span className='day-text'>day</span>
                <span className='day-number'>{day}</span>
              </div>
              <div className="challenges-left-details">
                <div className="left-detail-item">
                  <h3>Enter Today's Challenge:</h3>
                  <textarea
                    type="text"
                    name="short_description"
                    value={challenge.short_description}
                    onChange={handleChange}
                    placeholder="Enter challenge prompt"
                  />
                </div>
                <div className="left-detail-item">
                  <h3>Description:</h3>
                  <textarea
                    type="text"
                    name="description"
                    value={challenge.description}
                    onChange={handleChange}
                    placeholder="Enter challenge description"
                  ></textarea>
                </div>
                <div className="left-detail-item">
                  <h3>Screens Required</h3>
                  <textarea
                    type="text"
                    name="requirement"
                    value={challenge.requirement}
                    onChange={handleChange}
                    placeholder="Requirements"
                  ></textarea>
                </div>
                <div className="left-detail-item">
                  <h3>Name</h3>
                  <input
                    type="text"
                    name="name"
                    value={challenge.name}
                    onChange={handleChange}
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="challenges-left-bottom">
                <Button btnText={`Save`} clickFunc={postCreateEditChallenge}>Done</Button>
              </div>
            </div>
            <div className="challenges-right">
              <div className="challenges-right-top">
                <div className="design-logo-title">
                  <UploadButton
                    options={bytescaleOptions}
                    onComplete={(files) => {
                      if (files.length === 0) return;
                      const fileUrl = files.map((x) => x.fileUrl).join("\n");
                      setChallenge((prevFormData) => ({
                        ...prevFormData,
                        screen_logo: fileUrl,
                      }));
                    }}
                  >
                    {({ onClick }) => (
                      <button
                        style={{
                          width: "47px",
                          height: "47px",
                          borderRadius: "5px",
                        }}
                        className="upload-screen"
                        onClick={onClick}
                      >
                        {challenge.screen_logo ? (
                          <img
                            alt="screen_one"
                            src={challenge.screen_logo}
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <h6>Upload</h6>
                        )}
                      </button>
                    )}
                  </UploadButton>
                  <div className="design-title">
                    <input
                      type="text"
                      name="screen"
                      value={challenge.screen}
                      onChange={handleChange}
                      placeholder="Screen"
                    />
                    <input
                      style={{ marginTop: 3 }}
                      type="text"
                      name="screen_short_note"
                      value={challenge.screen_short_note}
                      onChange={handleChange}
                      placeholder="Screen short note"
                    />
                  </div>
                </div>
                <div className="refer-more-screens">
                  <button
                    type="button"
                  // onClick={() =>
                  //   window.open(question?.reference_link, "_blank")
                  // }
                  >
                    <span>Refer more screens</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M8 1L1 8"
                        stroke="white"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.15385 1H8V5.84615"
                        stroke="white"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="challenges-right-screens">
                <div className="right-screen-design">
                  <UploadButton
                    options={bytescaleOptions}
                    onComplete={(files) => {
                      if (files.length === 0) return;
                      const fileUrl = files.map((x) => x.fileUrl).join("\n");
                      setChallenge((prevFormData) => ({
                        ...prevFormData,
                        design_screen: fileUrl,
                      }));
                    }}
                  >
                    {({ onClick }) => (
                      <button
                        style={{
                          width: "276px",
                          height: "598px",
                        }}
                        className="upload-screen"
                        onClick={onClick}
                      >
                        {challenge.design_screen ? (
                          <img
                            alt="screen_one"
                            src={challenge.design_screen}
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <h3>Click to upload</h3>
                        )}
                      </button>
                    )}
                  </UploadButton>
                </div>
                <div className="right-divider">
                </div>
                <div className="right-screen-info">
                  <div className="right-info-wrap">
                    <div className="right-info-wrap-container">
                      <button
                        onClick={() =>
                          setChallenge((prev) => ({
                            ...prev,
                            difficulty_level: "simple",
                          }))
                        }
                        className="challenge-survey-button"
                        style={{
                          background: "rgba(245,235,0,0.4)"
                        }}
                      >
                        <span>Simple</span>
                        {challenge.difficulty_level === "simple" && <span className="sb-icon"> ✔️</span>}
                      </button>
                      <button
                        onClick={() =>
                          setChallenge((prev) => ({
                            ...prev,
                            difficulty_level: "medium",
                          }))
                        }
                        className="challenge-survey-button"
                        style={{
                          background: "rgba(255,124,2,0.4)"
                        }}
                      >
                        <span>Medium</span>
                        {challenge.difficulty_level === "medium" && <span className="sb-icon"> ✔️</span>}
                      </button>
                      <button
                        onClick={() =>
                          setChallenge((prev) => ({
                            ...prev,
                            difficulty_level: "tough",
                          }))
                        }
                        className="challenge-survey-button"
                        style={{
                          background: "rgba(255,0,0,0.4)"
                        }}
                      >
                        <span>Tough</span>
                        {challenge.difficulty_level === "tough" && <span className="sb-icon"> ✔️</span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMinimal />
    </div>
  );
};

export default Challenge;
