import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

import SeasonDayListRowTitle from "../components/season-day-list-row-title";
import SeasonDayListRowContent from "../components/season-day-list-row-content";
import FooterMinimal from "../components/footer-minimal";
import Navbar from "../components/navbar";
import "./season-day-list.scss";
import { getChallengeBySeason } from "../api/routes/challenge";
import Button from "../components/Button";

const SeasonDayList = (props) => {
  let { sid } = useParams();
  const history = useHistory();

  const [dayList, setDayList] = useState([]);
  const [loading, showLoading] = useState(false);
  const [dataLoader, setDataLoader] = useState('loading...');

  useEffect(() => {
    fetchChallengesSeasonWise();
  }, []);

  const fetchChallengesSeasonWise = async () => {
    try {
      showLoading(true)
      const response = await getChallengeBySeason(sid || 1);
      if (response && response.data) {
        showLoading(false)
        setDayList(response.data.seasons);
      } else {
        showLoading(false)
        setDataLoader('no data found')
      }
    } catch (error) { }
  };

  const handleCreateChallenge = () => {
    const dayArray = dayList.map((item) => item.day);
    const day = dayArray.length > 0 ? Math.max(...dayArray) + 1 : 1;
    history.push({
      pathname: "/challenge",
      state: { season: Number(sid), day: day, cid: null },
    });
  };

  return (
    <div>
      <Helmet>
        <title>SeasonDayList - 90 D Admin Dashboard</title>
        <meta
          property="og:title"
          content="SeasonDayList - 90 D Admin Dashboard"
        />
      </Helmet>
      <Navbar />
      <div className="content-wrap">
        <div className="container">
          <div className="title-section">
            <h1>Calendar</h1>
            <p>See the statistics of 90 days of challenge</p>
          </div>
          <div className="season-day-list-data">
            {/* <SeasonDayListRowTitle /> */}
            <table>
              <tr>
                <th style={{ width: '60px' }}>Day</th>
                <th style={{ width: '100px' }}>Questions</th>
                <th style={{ width: '200px', textAlign: 'right' }}>Difficulty Level:</th>
                <th style={{ width: '100px' }}>Simple</th>
                <th style={{ width: '100px' }}>Moderate</th>
                <th style={{ width: '100px' }}>Tough</th>
                <th>Actions</th>
              </tr>
              {loading && (
                <tr>
                  <td style={{ color: '#aaa' }}>{dataLoader}</td>
                </tr>
              )}
              {dayList &&
                dayList.map((season, index) => (
                  <SeasonDayListRowContent
                    key={index}
                    dayCount={season.day || 0}
                    totalQuestions={season.total_challenges || 0}
                    simpleCount={season.total_simple || 0}
                    moderateCount={season.total_medium || 0}
                    toughCount={season.total_tough || 0}
                  />
                ))}
            </table>

          </div>
        </div>
      </div>
      <FooterMinimal />
    </div>
  );
};

export default SeasonDayList;
