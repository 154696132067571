export const getColorByDayDifficulty = (syllabusDetails, dayToCheck) => {
  const details = syllabusDetails.find((item) => item.day === dayToCheck);
  const color = getColorByDifficulty(details?.difficulty_level);
  return color;
};

export const getColorByDifficulty = (difficulty) => {
  switch (difficulty) {
    case "simple":
      return "#F5EB00";
    case "medium":
      return "#FF7C02";
    case "tough":
      return "#FF0000";
    default:
      return "#D9D9D9";
  }
};
