import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import "./style.css";
import Seasons from "./views/seasons";
import SeasonDayList from "./views/season-day-list";
import Challenge from "./views/challenge";
import SyllabusDetails from "./views/syllabus-details";
import SeasonDayQuestionList from "./views/season-day-question-list";
import Home from "./views/home";
import NotFound from "./views/not-found";
import PrivateRoute from "./utils/PrivateRoute";
import { ToastContainer } from "react-toastify";
import Landing from "./views/landing";
import SyllabusList from "./views/syllabus-list";
import GridComponent from "./views/syllabus-grid";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <Router>
          <Switch>
            <PrivateRoute component={Landing} exact path="/landing" />
            <PrivateRoute component={Seasons} path="/seasons" />
            <PrivateRoute
              component={SeasonDayList}
              exact
              path="/season-day-list/season=:sid"
            />
            <PrivateRoute
              component={SeasonDayQuestionList}
              exact
              path="/season-day-question-list/season=:sid/:day"
            />
            <PrivateRoute component={Challenge} exact path="/challenge" />
            <PrivateRoute
              component={SyllabusDetails}
              exact
              path="/syllabus-details"
            />
            <PrivateRoute component={SyllabusList} exact path="/syllabus" />
            <PrivateRoute
              component={GridComponent}
              exact
              path="/syllabus-grid"
            />
            <Route component={Home} exact path="/" />
            <Route component={NotFound} path="**" />
            <Redirect to="**" />
          </Switch>
        </Router>
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
