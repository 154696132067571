import { getApi, postApi } from "../base";

export const getSeasons = () => {
  const res = getApi(`design-admin/seasons`);
  return res;
};

export const getSeason = (sid) => {
  const res = getApi(`design-admin/season/edit/${sid}`);
  return res;
};

export const createSeason = (payload) => {
  const res = postApi(`design-admin/season/create`, payload);
  return res;
};

export const editSeason = (sid, payload) => {
  const res = postApi(`design-admin/season/edit/${sid}`, payload);
  return res;
};

export const editSeasonStatus = (sid, payload) => {
  const res = postApi(`design-admin/season/change_status/${sid}`, payload);
  return res;
};
