import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";

import SeasonDayQuestionListRowContent from "../components/season-day-question-list-row-content";
import FooterMinimal from "../components/footer-minimal";
import Navbar from "../components/navbar";
import "./season-day-question-list.css";
import { getChallengeBySeasonDay } from "../api/routes/challenge";

const SeasonDayQuestionList = (props) => {
  let { sid, day } = useParams();
  const [dayList, setDayList] = useState([]);
  const [loading, showLoading] = useState(false);
  const [dataLoader, setDataLoader] = useState('loading...');

  useEffect(() => {
    fetchChallengesSeasonDayWise();
  }, []);

  const fetchChallengesSeasonDayWise = async () => {
    try {
      showLoading(true)
      const response = await getChallengeBySeasonDay(sid, day);
      if (response && response.data) {
        showLoading(false)
        setDayList(response.data.challenges);
      } else {
        showLoading(false)
        setDataLoader('no data found')
      }
    } catch (error) { }
  };

  return (
    <div>
      <Helmet>
        <title>SeasonDayQuestionList - 90 D Admin Dashboard</title>
        <meta
          property="og:title"
          content="SeasonDayQuestionList - 90 D Admin Dashboard"
        />
      </Helmet>
      <Navbar />
      <div className="content-wrap">
        <div className="container">
          <div className="title-section">
            <h1>{day ? `Day ${day}` : 0}</h1>
            <p>See the statistics of challenges mapped to a particular day</p>
          </div>
          <div className="season-day-list-data">
            <table>

              <tr>
                <th style={{ width: '60px' }}>Question</th>
                <th style={{ width: '40%' }}>&nbsp;</th>
                <th>Difficulty Level</th>
                <th>Action</th>
              </tr>
              {loading && (
                <tr>
                  <td style={{ color: '#aaa' }}>{dataLoader}</td>
                </tr>
              )}

              {dayList &&
                dayList.map((challenge, index) => (
                  <SeasonDayQuestionListRowContent
                    key={index}
                    questionNumber={index + 1}
                    dayCount={challenge.day}
                    cid={challenge.id}
                    questionTitle={challenge.name}
                    difficultyLevel={challenge.difficulty_level}
                  />
                ))}
            </table>

          </div>
        </div>
      </div>
      <FooterMinimal />
    </div>
  );
};

export default SeasonDayQuestionList;
