import apiClient from "./axiosInstance";

export const getApi = async (path, payload) => {
  const res = await apiClient.get(path, payload);
  return res;
};

export const postApi = async (path, payload) => {
  const res = await apiClient.post(path, payload);
  return res;
};

export const putApi = async (path, payload) => {
  const res = await apiClient.put(path, payload);
  return res;
};

export const deleteApi = async (path, payload) => {
  const res = await apiClient.delete(path, payload);
  return res;
};
