import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const navigateToScreenOrToast = (dataObject, history) => {
  if (dataObject.toast && dataObject.toast) {
    const toast_message = dataObject.toast.message || "";
    if (dataObject.toast.type === "SUCCESS") {
      toast.success(toast_message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  if (dataObject.redirect && dataObject.redirect["name"]) {
    window.location.href = `/${dataObject.redirect.name}`;
    return;
  }
};
