import React from "react";
import { Helmet } from "react-helmet";
import FooterMinimal from "../components/footer-minimal";
import Navbar from "../components/navbar";

const styles = {
  box: {
    padding: "4rem",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#ddd",
    cursor: "pointer",
    margin: "1rem",
    width: "250px",
    maxWidth: "100%"
  },
  landingWrap: {
    alignItems: "center",
    display: "flex"
  }
};

const landing = () => {
  const navigateToSeason = () => {
    window.location.href = "/seasons";
  };
  const navigateToSyllabus = () => {
    window.location.href = "/syllabus";
  };
  return (
    <div>
      <Helmet>
        <title>90 D Admin Dashboard</title>
        <meta property="og:title" content="90 D Admin Dashboard" />
      </Helmet>
      <Navbar />
      <div className="content-wrap" style={styles.landingWrap}>
        <div className="container">
          <button onClick={navigateToSeason} style={styles.box}>
            <h1>Season</h1>
          </button>
          <button onClick={navigateToSyllabus} style={styles.box}>
            <h1>Syllabus</h1>
          </button>
        </div>
      </div>

      <FooterMinimal />
    </div>
  );
};

export default landing;
