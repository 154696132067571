import React, { useEffect, useState } from "react";
import "./syllabus-list.scss";
import {
  changeSyllabusStatus,
  createSyllabus,
  editSyllabus,
  getSyllabus,
} from "../api/routes/syllabus";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";
import { Helmet } from "react-helmet";
import FooterMinimal from "../components/footer-minimal";
import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom";

const SyllabusList = () => {
  const history = useHistory();
  const [popupError, setPopupError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [syllabusList, setSyllabusList] = useState([]);
  const [syllabus, setSyllabus] = useState({ name: "" });
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    fetchSyllabus();
    return () => setForceUpdate(false);
  }, [forceUpdate]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setSyllabus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const fetchSyllabus = async () => {
    try {
      const response = await getSyllabus();
      if (response && response.data) {
        setSyllabusList(response.data.syllabuses);
      }
    } catch (error) { }
  };
  const handleCreateOrUpdate = () => {
    if (edit) {
      return updateSyllabus();
    }
    postSyllabus();
  };
  const postSyllabus = async () => {
    if (!syllabus.name) {
      setPopupError(true)
      return
    }
    setPopupError(false)
    try {
      const response = await createSyllabus(syllabus);
      if (response && response.data) {
        navigateToScreenOrToast(response.data);
        setForceUpdate((prev) => !prev);
      }
    } catch (error) { }
  };
  const updateSyllabus = async () => {
    if (!syllabus.name) {
      setPopupError(true)
      return
    }
    setPopupError(false)
    try {
      const response = await editSyllabus(edit.id, syllabus);
      if (response && response.data) {
        handleCancelEdit();
        navigateToScreenOrToast(response.data);
        setForceUpdate((prev) => !prev);
      }
    } catch (error) { }
  };
  const updateSyllabusStatus = async (sid, payload) => {
    try {
      const response = await changeSyllabusStatus(sid, payload);
      if (response && response.data) {
        navigateToScreenOrToast(response.data);
        setForceUpdate((prev) => !prev);
      }
    } catch (error) { }
  };
  const navigateToSyllabus = (item) => {
    history.push({
      pathname: "/syllabus-grid",
      state: {
        sid: Number(item.id),
        sname: item.name,
      },
    });
  };
  const showSyllabusPopup = () => {
    setShowPopup(true)
  }
  const hideSyllabusPopup = () => {
    setShowPopup(false)
    setPopupError(false)
  }
  const handleEdit = (event, item) => {
    setShowPopup(true)
    setEdit(item);
    setSyllabus({ name: item.name });
    event.stopPropagation();
  };
  const handleCancelEdit = () => {
    setEdit(null);
    setSyllabus({ name: "" });
    setShowPopup(false)
    setPopupError(false)
  };
  const handleUpdateStatus = (event, item) => {
    event.stopPropagation();
    const userConfirmed = window.confirm(
      "Do you want to update syllabus status?"
    );
    if (userConfirmed) {
      const payload = { status: item.status ? 0 : 1 }; // 0 -> False; 1 -> True
      updateSyllabusStatus(item.id, payload);
    }
  };
  return (
    <div className="syllabus-list-container">
      <Helmet>
        <title>Syllabus - 90 D Admin Dashboard</title>
        <meta property="og:title" content="Syllabus - 90 D Admin Dashboard" />
      </Helmet>
      <Navbar />

      <div className="content-wrap">
        <div className="container">
          <div className="title-section">
            <h1>Syllabus</h1>
            <p>All Syllabus</p>
          </div>
          <div className="syllabus-list-wrap">
            {syllabusList.map((item, index) => (
              <div
                onClick={() => navigateToSyllabus(item)}
                key={index}
                className="syllabus-list-item"
              >
                <h3>{item.name}</h3>
                <div className="syllabus-icons">
                  <span
                    onClick={(event) => handleEdit(event, item)}
                    className="edit-syllabus"
                  >
                    📝
                  </span>
                  <span
                    onClick={(event) => handleUpdateStatus(event, item)}
                    className="status-syllabus"
                  >
                    {item.status ? "✅" : "⏹️"}
                  </span>
                </div>
              </div>
            ))}
            <div
              onClick={showSyllabusPopup}
              className="syllabus-list-item"
            >
              <h3><span>+</span>New Syllabus</h3>
              <div className="syllabus-icons">Add a new syllabus</div>
            </div>
          </div>

          {showPopup && (

            <div className="syllabus-popup">
              <div className="syllabus-popup-content">
                <h4>{edit ? "Edit" : "Create"} a Syllabus</h4>
                <div className="syllabus-popup-form">
                  <input
                    type="text"
                    name="name"
                    value={syllabus.name}
                    onChange={handleChange}
                    placeholder="Enter syllabus title"
                    className="syllabus-popup-input"
                  />
                  <div className="syllabus-popup-btns">

                    <button onClick={handleCreateOrUpdate} className="syllabus-list-button">
                      {edit ? "Update" : "Create"}
                    </button>
                    {edit ? (
                      <button
                        onClick={handleCancelEdit}
                        className="syllabus-list-button"
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        onClick={hideSyllabusPopup}
                        className="syllabus-list-button"
                      >
                        Close
                      </button>
                    )}
                  </div>
                  {popupError && (<div className="syllabus-popup-error">Please input a valid Title</div>)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <FooterMinimal />
    </div>
  );
};

export default SyllabusList;
