import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";

import FooterMinimal from "../components/footer-minimal";
import Navbar from "../components/navbar";
import "./syllabus-details.css";
import {
  createOrUpdateSyllabusDetails,
  getSyllabusBySeason,
} from "../api/routes/syllabus";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";

const Syllabus = (props) => {
  const location = useLocation();
  const { state } = location;
  const { sid } = state || {}; // syllabus id

  const [syllabusDetails, setSyllabusDetails] = useState([]);
  useEffect(() => {
    fetchListSyllabusDetails();
  }, []);

  const postOrUpdateSyllabusDetails = async () => {
    const payload = { day: 1, difficulty_level: "simple" }; // 0 -> False; 1 -> True
    try {
      const response = await createOrUpdateSyllabusDetails(sid, payload);
      if (response && response.data) {
        // navigateToScreenOrToast(response.data);
      }
    } catch (error) {
      console.log("🚀 ~ updateSyllabus ~ error:", error);
    }
  };

  const fetchListSyllabusDetails = async () => {
    try {
      const response = await getSyllabusBySeason(sid);
      if (response && response.data) {
        console.log(
          "🚀 ~ fetchListSyllabusDetails ~ response.data:",
          response.data
        );
        setSyllabusDetails(response.data.syllabusdetails);
      }
    } catch (error) {}
  };

  return (
    <div className="syllabus-container">
      <Helmet>
        <title>Syllabus - 90 D Admin Dashboard</title>
        <meta property="og:title" content="Syllabus - 90 D Admin Dashboard" />
      </Helmet>
      <div className="syllabus-home">
        <div className="syllabus-frame-challengestitle">
          <span className="syllabus-text">L1 Person</span>
        </div>
        <div className="syllabus-frame1736">
          <div className="syllabus-leftcontainer">
            <div className="syllabus-group1723">
              <span className="syllabus-text01">
                <span>Day 1</span>
              </span>
              <span className="syllabus-text03">
                <span>Day 2</span>
              </span>
              <span className="syllabus-text05">
                <span>Day 3</span>
              </span>
              <span className="syllabus-text07">
                <span>Day 4</span>
              </span>
              <span className="syllabus-text09">
                <span>Day 5</span>
              </span>
              <span className="syllabus-text11">
                <span>Day 6</span>
              </span>
              <span className="syllabus-text13">
                <span>Day 7</span>
              </span>
              <span className="syllabus-text15">
                <span>Day 8</span>
              </span>
              <span className="syllabus-text17">
                <span>Day 9</span>
              </span>
              <span className="syllabus-text19">
                <span>Day 10</span>
              </span>
            </div>
            <div className="syllabus-group1718">
              <span className="syllabus-text21">
                <span>+40 days</span>
              </span>
              <div className="syllabus-rectangle646">1</div>
              <div className="syllabus-rectangle647">2</div>
              <div className="syllabus-rectangle648">3</div>
              <div className="syllabus-rectangle649">44</div>
              <div className="syllabus-rectangle650">5</div>
              <div className="syllabus-rectangle651">6</div>
              <div className="syllabus-rectangle652">7</div>
              <div className="syllabus-rectangle653">8</div>
              <div className="syllabus-rectangle654">9</div>
              <div className="syllabus-rectangle655">0</div>
            </div>
            <div className="syllabus-group1715">
              <span className="syllabus-text23">
                <span>+10 days</span>
              </span>
              <div className="syllabus-rectangle6461">11</div>
              <div className="syllabus-rectangle6471">2</div>
              <div className="syllabus-rectangle64801">3</div>
              <div className="syllabus-rectangle6491">4</div>
              <div className="syllabus-rectangle65001">5</div>
              <div className="syllabus-rectangle6511">6</div>
              <div className="syllabus-rectangle6521">7</div>
              <div className="syllabus-rectangle6531">8</div>
              <div className="syllabus-rectangle6541">9</div>
              <div className="syllabus-rectangle6551">0</div>
            </div>
            <div className="syllabus-group1719">
              <span className="syllabus-text25">
                <span>+50 days</span>
              </span>
              <div className="syllabus-rectangle6462">1</div>
              <div className="syllabus-rectangle6472">2</div>
              <div className="syllabus-rectangle64802">3</div>
              <div className="syllabus-rectangle6492">4</div>
              <div className="syllabus-rectangle65002">55</div>
              <div className="syllabus-rectangle6512">6</div>
              <div className="syllabus-rectangle6522">7</div>
              <div className="syllabus-rectangle6532">8</div>
              <div className="syllabus-rectangle6542">9</div>
              <div className="syllabus-rectangle6552">0</div>
            </div>
            <div className="syllabus-group1716">
              <span className="syllabus-text27">
                <span>+20 days</span>
              </span>
              <div className="syllabus-rectangle6463">1</div>
              <div className="syllabus-rectangle6473">22</div>
              <div className="syllabus-rectangle64803">3</div>
              <div className="syllabus-rectangle6493">4</div>
              <div className="syllabus-rectangle65003">5</div>
              <div className="syllabus-rectangle6513">6</div>
              <div className="syllabus-rectangle6523">7</div>
              <div className="syllabus-rectangle6533">8</div>
              <div className="syllabus-rectangle6543">9</div>
              <div className="syllabus-rectangle6553">0</div>
            </div>
            <div className="syllabus-group1720">
              <span className="syllabus-text29">
                <span>+60 days</span>
              </span>
              <div className="syllabus-rectangle6464">1</div>
              <div className="syllabus-rectangle6474">2</div>
              <div className="syllabus-rectangle64804">3</div>
              <div className="syllabus-rectangle6494">4</div>
              <div className="syllabus-rectangle65004">5</div>
              <div className="syllabus-rectangle6514">66</div>
              <div className="syllabus-rectangle6524">7</div>
              <div className="syllabus-rectangle6534">8</div>
              <div className="syllabus-rectangle6544">9</div>
              <div className="syllabus-rectangle6554">0</div>
            </div>
            <div className="syllabus-group1717">
              <span className="syllabus-text31">
                <span>+30 days</span>
              </span>
              <div className="syllabus-rectangle6465">1</div>
              <div className="syllabus-rectangle6475">2</div>
              <div className="syllabus-rectangle64805">33</div>
              <div className="syllabus-rectangle6495">4</div>
              <div className="syllabus-rectangle65005">5</div>
              <div className="syllabus-rectangle6515">6</div>
              <div className="syllabus-rectangle6525">7</div>
              <div className="syllabus-rectangle6535">8</div>
              <div className="syllabus-rectangle6545">9</div>
              <div className="syllabus-rectangle6555">0</div>
            </div>
            <div className="syllabus-group1721">
              <span className="syllabus-text33">
                <span>+70 days</span>
              </span>
              <div className="syllabus-rectangle6466">1</div>
              <div className="syllabus-rectangle6476">2</div>
              <div className="syllabus-rectangle64806">3</div>
              <div className="syllabus-rectangle6496">4</div>
              <div className="syllabus-rectangle65006">5</div>
              <div className="syllabus-rectangle6516">6</div>
              <div className="syllabus-rectangle6526">77</div>
              <div className="syllabus-rectangle6536">8</div>
              <div className="syllabus-rectangle6546">9</div>
              <div className="syllabus-rectangle6556">0</div>
            </div>
            <div className="syllabus-group1722">
              <span className="syllabus-text35">
                <span>+80 days</span>
              </span>
              <div className="syllabus-rectangle6467">1</div>
              <div className="syllabus-rectangle6477">2</div>
              <div className="syllabus-rectangle64807">3</div>
              <div className="syllabus-rectangle6497">4</div>
              <div className="syllabus-rectangle65007">5</div>
              <div className="syllabus-rectangle6517">6</div>
              <div className="syllabus-rectangle6527">7</div>
              <div className="syllabus-rectangle6537">88</div>
              <div className="syllabus-rectangle6547">9</div>
              <div className="syllabus-rectangle6557">0</div>
            </div>
            <div className="syllabus-group1714">
              <div className="syllabus-rectangle64808">1</div>
              <div className="syllabus-rectangle6478">2</div>
              <div className="syllabus-rectangle64809">3</div>
              <div className="syllabus-rectangle6498">4</div>
              <div className="syllabus-rectangle65008">5</div>
              <div className="syllabus-rectangle65009">6</div>
              <div className="syllabus-rectangle6528">7</div>
              <div className="syllabus-rectangle6538">8</div>
              <div className="syllabus-rectangle6548">9</div>
              <div className="syllabus-rectangle6558">0</div>
              <span className="syllabus-text37">
                <span>Start</span>
              </span>
              <span className="syllabus-text39">
                <span>🏁</span>
              </span>
            </div>
            <div className="syllabus-group1724">
              <span className="syllabus-text41">
                <span>Win</span>
              </span>
              <span className="syllabus-text43">
                <span>🏅</span>
              </span>
            </div>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1575995872537-3793d29d972c?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGlsbHVzdHJhdGlvbnN8ZW58MHx8fHwxNzAwNjM3NDYwfDA&amp;ixlib=rb-4.0.3&amp;h=700"
            className="syllabus-image"
          />
        </div>
      </div>
      <FooterMinimal rootClassName="footer-minimal-root-class-name5"></FooterMinimal>
      <Navbar rootClassName="navbar-root-class-name5"></Navbar>
    </div>
  );
};

export default Syllabus;
