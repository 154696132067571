export const bytescaleOptions = {
  apiKey: "public_FW25bq3ExNXqRPzvvkufWUEiitGA", // This is your API key.
  editor: {
    images: {
      allowResizeOnMove: false,
      crop: false,
    },
  },
  maxFileCount: 1,
  path: {
    folderPath: "/90d-bucket",
    folderPathVariablesEnabled: true,
  },
};
