import { getApi, postApi } from "../base";

export const getSyllabus = () => {
  const res = getApi(`design-admin/syllabuses`);
  return res;
};

export const createSyllabus = (payload) => {
  const res = postApi(`design-admin/syllabus/create`, payload);
  return res;
};

export const editSyllabus = (syllabusID, payload) => {
  const res = postApi(`design-admin/syllabus/edit/${syllabusID}`, payload);
  return res;
};

export const changeSyllabusStatus = (syllabusID, payload) => {
  const res = postApi(
    `design-admin/syllabus/change_status/${syllabusID}`,
    payload
  );
  return res;
};

export const createOrUpdateSyllabusDetails = (syllabusID, payload) => {
  const res = postApi(
    `design-admin/syllabus/create_update_details/${syllabusID}`,
    payload
  );
  return res;
};

export const getSyllabusBySeason = (sid) => {
  const res = getApi(`design-admin/syllabus/list_syllabus_details/${sid}`);
  return res;
};
