import { getApi, postApi } from "../base";

export const createChallenge = (sid, day, payload) => {
  const res = postApi(`design-admin/challenge/create/${sid}/${day}`, payload);
  return res;
};

export const getChallengeBySeason = (sid) => {
  const res = getApi(`design-admin/challenges/calendar/${sid}`);
  return res;
};

export const getChallengeBySeasonDay = (sid, day) => {
  const res = getApi(`design-admin/challenges/day/${sid}/${day}`);
  return res;
};

export const getEditChallenge = (cid) => {
  const res = getApi(`design-admin/challenge/edit/${cid}`);
  return res;
};

export const postEditChallenge = (cid, payload) => {
  const res = postApi(`design-admin/challenge/edit/${cid}`, payload);
  return res;
};
