import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./navbar.scss";
import { useStore } from "react-redux";


const Navbar = (props) => {
  const auth = useStore().getState().auth;
  return (
    <div className={`navbar-container ${props.rootClassName} `}>
      <div className="container">
        <div className="navbar-wrap">
          <Link to="/" className="navbar-navlink">
            <img
              alt="90d"
              src="/external/unioni217-xgu9.svg"
              className="navbar-logo"
            />
          </Link>
          <div className="navbar-center">
            <Link to="/" className="navbar-navlink">Home</Link>
            <Link to="/seasons" className="navbar-navlink">Season</Link>
            <Link to="/season-day-list/season=1" className="navbar-navlink">Calendar</Link>
            <Link to="/season-day-list/season=1" className="navbar-navlink">Day</Link>
            <Link to="/challenge" className="navbar-navlink">Challenges</Link>
            <Link to="/challenge" className="navbar-navlink">Create/Modify</Link>
          </div>
          <div className="navbar-right-elements">
            {auth.isAuthenticated ? (
              'My Account'
            ) : (
              <Link to="/login" className="navbar-navlink navbar-btn">
                Log in
              </Link>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};

Navbar.defaultProps = {
  rootClassName: "",
};

Navbar.propTypes = {
  image_src1: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default Navbar;