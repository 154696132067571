import React, { useState } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./home.scss";
import { authVerify } from "../api/routes/auth";
import { useDispatch, useStore } from "react-redux";
import { loginUser } from "../store/reducers/authSlice";
import Button from "../components/Button";

const Home = (props) => {
  const auth = useStore().getState().auth;
  if (auth.isAuthenticated) {
    window.location.href = "/landing";
  }
  const dispatch = useDispatch();
  const [cred, setCred] = useState({
    username: "",
    password: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCred((prevCred) => ({
      ...prevCred,
      [name]: value,
    }));
  };
  const handleLogin = async () => {
    if (!cred.username || !cred.password) {
      return;
    }
    try {
      const response = await authVerify(cred);
      if (response && response.data && response.data.status) {
        dispatch(
          loginUser({
            csrf_token: response.data.csrf_token,
            isAuthenticated: true,
          })
        );
        window.location.href = "/landing";
      }
    } catch (error) { }
  };
  return (
    <div className="home-container">
      <Helmet>
        <title>90 D Admin Dashboard</title>
        <meta property="og:title" content="90 D Admin Dashboard" />
      </Helmet>
      <Navbar />
      <div className="content-wrap">
        <div className="container">
          <div className="home-content">
            <h1 className="home-text">90D Storyteller</h1>
            <p>
              As a curator of innovation and mentorship, you are about to
              empower a vibrant community of UI/UX designers with your
              visionary challenges and questions. Here, you craft the
              narrative that guides aspiring creatives through the intricacies
              of design
            </p>
            <div className="home-btn-group">
              <input
                type="text"
                name="username"
                autoFocus
                placeholder="Username"
                autoComplete="username"
                onChange={handleChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                autoComplete="off"
                onChange={handleChange}
              />
              <Button btnText={`Login`} clickFunc={handleLogin} />
            </div>
          </div>
        </div>
      </div>
      <FooterMinimal />
    </div>
  );
};

export default Home;
