import React from 'react'

import PropTypes from 'prop-types'

import './footer-minimal.scss'

const FooterMinimal = (props) => {
  return (
    <div className={`footer-minimal ${props.rootClassName} `}>
      <div className="container">
        <div className='footer-minimal-wrap'>
          <div className="copyright">
            Copyright &copy; Etherflair LLP.
          </div>
          <div className='footer-minimal-links'>
            <a
              href="#"
              target="_blank"
              rel="noreferrer noopener"
            >Terms
            </a>
            <a
              href="#"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy
            </a>
            <a
              href="#"
              target="_blank"
              rel="noreferrer noopener"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

FooterMinimal.defaultProps = {
  rootClassName: '',
}

FooterMinimal.propTypes = {
  rootClassName: PropTypes.string,
}

export default FooterMinimal